export const DEBOUNCE_TIME_IN_MS = 800;

export const COUNTRIES = [
  { label: 'Germany', value: 'DE' },
  { label: 'France', value: 'FR' },
  { label: 'Netherlands', value: 'NL' },
];

export const STATUS = {
  OPERATIONAL: 'operational',
  PARTIALLY_OPERATIONAL: 'partially-operational',
  CLOSED: 'closed',
  INACTIVE: 'inactive',
} as const;

export const STATUS_OPTIONS = [
  { label: 'Operational', value: STATUS.OPERATIONAL },
  { label: 'Partially Operational', value: STATUS.PARTIALLY_OPERATIONAL },
  { label: 'Closed', value: STATUS.CLOSED },
  { label: 'Inactive', value: STATUS.INACTIVE },
];

export const PERMISSIONS = {
  PDT: 'write:hub-manager_pdt',
  CONFIGURATION: 'write:hub-manager_configuration',
} as const;

export const QUERY_KEYS = {
  HUBS: ['v1', 'hubs'],
  GET_HUB_REQUEST: ['v1', 'hub', undefined],
  GET_ALL_TURFS_REQUEST: ['v1', 'hub', undefined, 'turfs'],
};

export const ROUTE_PATHS = {
  HUB_MANAGER: '/hub-manager',
  HUB_SETTINGS: '/hub-settings',
  HUB_DELIVERY_AREA: '/hub-manager/delivery-area',
  AUDIT_LOGS_PAGE: '/hub-manager/audit-logs',
} as const;

export const EMERGENCY_CLOSURE_REASONS = {
  UNDERSTAFFING: 'understaffing',
  WEATHER: 'weather',
  REMODELLING: 'remodelling',
  EXTERNAL_FACTOR: 'external_factor',
  PROPERTY_ISSUE: 'property_issue',
  EQUIPMENT: 'equipment',
  OTHER: 'other',
} as const;

export const SCHEDULE_CLOSURE_REASONS = {
  EQUIPMENT: 'equipment',
  EXTERNAL_FACTOR: 'external_factor',
  GENERIC_DELAYS: 'generic_delays', //deprecated
  HIGH_PDT: 'high_pdt', //deprecated
  HOLIDAY: 'holiday',
  MAINTENANCE: 'maintenance',
  RELOCATION: 'relocation',
  SCHEDULED: 'scheduled', //deprecated
} as const;

export const SCHEDULE_CLOSURE_REASONS_OPTION = [
  SCHEDULE_CLOSURE_REASONS.EQUIPMENT,
  SCHEDULE_CLOSURE_REASONS.EXTERNAL_FACTOR,
  SCHEDULE_CLOSURE_REASONS.MAINTENANCE,
  SCHEDULE_CLOSURE_REASONS.HOLIDAY,
  SCHEDULE_CLOSURE_REASONS.RELOCATION,
];

export const FROM = 'from';
export const TO = 'to';

export const DEFAULT_START_TIME = '00:00';
export const DEFAULT_END_TIME = '06:00';
export const DEFAULT_END_DAY_TIME = '24:00';

export const DEFAULT_DATE_TIME = {
  startTime: { value: DEFAULT_START_TIME, label: DEFAULT_START_TIME },
  endTime: { value: DEFAULT_END_TIME, label: DEFAULT_END_TIME },
};

export const WEEKDAYS_NO = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
} as const;

export const WEEKDAYS = {
  [WEEKDAYS_NO.MONDAY]: 'monday',
  [WEEKDAYS_NO.TUESDAY]: 'tuesday',
  [WEEKDAYS_NO.WEDNESDAY]: 'wednesday',
  [WEEKDAYS_NO.THURSDAY]: 'thursday',
  [WEEKDAYS_NO.FRIDAY]: 'friday',
  [WEEKDAYS_NO.SATURDAY]: 'saturday',
  [WEEKDAYS_NO.SUNDAY]: 'sunday',
} as const;

export const EVENTS = [
  { label: 'Turf Created', value: 'turf_create' },
  { label: 'Turf Updated', value: 'turf_update' },
  { label: 'Turf Deleted', value: 'turf_delete' },
  { label: 'Hub Created', value: 'hub_create' },
  { label: 'Hub Updated', value: 'hub_update' },
  { label: 'Hub Deleted', value: 'hub_delete' },
];

export const AUDITLOG_HEADERS = ['hub-name', 'turf-name', 'date', 'event', 'user'] as const;
