import ImportTurfDrawer from 'components/ImportTurfDrawer';
import { shouldBeAbleToUploadTurf } from 'lib/permissions';
import { useState } from 'react';
import { useTranslation } from 'hooks';

const ImportTurfButton = () => {
  const { t } = useTranslation();
  const [isTurfImportDrawerVisible, setIsTurfImportDrawerVisible] = useState(false);

  const isUploadTurfActivated = shouldBeAbleToUploadTurf();
  if (!isUploadTurfActivated) return null;

  const toggleImportTurfDrawer = () => {
    setIsTurfImportDrawerVisible((isTurfImportDrawerVisible) => !isTurfImportDrawerVisible);
  };

  return (
    <div>
      {isTurfImportDrawerVisible ? <ImportTurfDrawer onClose={toggleImportTurfDrawer} /> : null}
      <button
        className="mr-2 flex items-center justify-center text-white bg-flinkPink leading-none p-4 rounded fontFamily-sans text-lg font-extrabold h-13 disabled:bg-flinkGray-light w-40"
        onClick={toggleImportTurfDrawer}
        data-testid="importTurfButton"
      >
        {t('components.import-turf-button.import')}
      </button>
    </div>
  );
};

export default ImportTurfButton;
