import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconArrowDownLg, IconArrowUpLg } from 'assets/icons';
import { useOutsideClick, useKeyPress, useTranslation } from 'hooks';

export type Option = {
  value: string;
  label: string;
};

type Props = {
  options?: Array<Option>;
  onSelect: (value: Array<string>) => void;
  selectedOptions?: Array<Option>;
  placeholder?: string;
  variant?: string;
};

const MultiSelect = ({
  options,
  onSelect,
  selectedOptions = [],
  placeholder = 'Select...',
  variant = 'medium',
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setOpen] = useState(false);
  const disabled = !options?.length;
  const Icon = isOpen ? IconArrowUpLg : IconArrowDownLg;
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onClose();
    }
  });

  const onChange = (option: Option) => {
    const shoudlDeselect = selectedOptions.some(
      (selectedOpt) => selectedOpt.value === option.value
    );

    const newSelectedOptions = shoudlDeselect
      ? selectedOptions.filter((selectedOpt) => selectedOpt.value !== option.value)
      : [...selectedOptions, option];

    const newSelectedOptionsValues = newSelectedOptions.map((option: Option) => option.value);

    onSelect(newSelectedOptionsValues);
  };

  const onCheck = (option: Option) => selectedOptions.some((item) => item.value === option.value);
  const displayValue = useMemo(() => {
    switch (selectedOptions.length) {
      case 0:
        return placeholder;
      case 1:
        return selectedOptions[0].label;
      default:
        return t('components.multiselect.display-value', {
          name: selectedOptions[0].label,
          count: selectedOptions.length - 1,
        });
    }
  }, [placeholder, selectedOptions, t]);

  return (
    <div className={`${variant} h-12 relative`} ref={ref}>
      <button
        className={`${
          disabled ? 'cursor-default' : 'cursor-pointer'
        } w-full h-full block truncate text-base rounded-lg border-2 pl-3 pr-8 text-left placeholder-flinkGray-light border-flinkGray bg-flinkGray-medium focus:outline-none`}
        onClick={() => setOpen(!isOpen)}
        type="button"
        disabled={disabled}
      >
        {displayValue}
        <span className="absolute inset-y-0 right-0 flex items-center px-2 pr-3">
          <Icon className="w-5 h-5" />
        </span>
      </button>
      {isOpen && options && (
        <ul className="absolute w-fit max-w-xs z-20 bg-flinkGray max-h-56 rounded-lg py-1 border-0 overflow-auto text-sm">
          {options.map((option: Option) => (
            <li key={option.value} className="hover:bg-flinkGray-medium">
              <label className="flex items-center p-2 pr-4 my-0.5 cursor-default">
                <input
                  type="checkbox"
                  value={option.value}
                  checked={onCheck(option)}
                  onChange={() => onChange(option)}
                />
                <span className="ml-3 block truncate text-base">{option.label}</span>
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(MultiSelect);
